export const MIXED_VALUE = 'MIXED_VALUE';
export const MULTIPLE_VALUES_SPLITTER = /[\s,\n]+/;
export const UNCOVERED_NETWORK_TRAFFIC = 10; // increase 10% traffic for uncovered traffic on logs https://help.aliyun.com/document_detail/40164.html

export const pluginData = {
    "geo" : "Geo Country access",
    "url_rule": "Url Rule",
    "badrobots": "Bot",
    "blacklist" : "IP Blacklist",
    "limit_req" : "Rate Limiting",
    "whitelist" : "IP WhiteList",
    "error_code": "Custom Error Page",
    "safe_detect" : "Security Check",
    "source_conn" : "Origin Server Protection",
    "redirect_check" : "Shield Detection",
    "redirect_white_urls":  "Redirect White Url",
    "proxy_host" : "Proxy Header Configuration",
    "strategies": "Websocket",
    "sslConfig" : "Advanced Rules",
    "cacheConfig" : "Cache Strategy",
    "sourceConfig" : "ACME Configuration"
}

export const UPDATER_MESSAGE = "Redeploy task did not finish on time";