<template>
  <ul class="cert-action-area">
    <li v-if="!domain.name.startsWith('*.')">
      <CButton variant="ghost"
               color="info"
               size="sm"
               :disabled="disabled || (domain.sslConfig.cert_id && domain.sslConfig.cert_type == 'HTTP') || is_bypass_acme"
               @click="issueCertType(domain, 'HTTP')">
        <CIcon :name="check_icon(domain)" v-if="hasSslCert(domain) && domain.sslConfig.cert_type == 'HTTP'"/>
        <CIcon name="cil-plus" v-else-if="!hasSslCert(domain)"/>
        {{ $t('cert.HTTPCert') }}
      </CButton>
    </li>
    <li>
      <CButton variant="ghost"
               color="info"
               size="sm"
               :disabled="disabled || (domain.sslConfig.cert_id && domain.sslConfig.cert_type == 'DNS') || is_bypass_acme"
               @click="issueCertType(domain, 'DNS')"
               v-c-tooltip="{content: alias_dns, placement: 'top'}">
        <CIcon :name="check_icon(domain)" v-if="hasSslCert(domain) && domain.sslConfig.cert_type == 'DNS'"/>
        <CIcon name="cil-plus" v-else-if="!hasSslCert(domain)"/>
        {{ $t('cert.DNSCert') }}
      </CButton>
    </li>
    <li>
      <CButton variant="ghost"
               color="info"
               size="sm"
               :disabled="disabled"
               @click="uploadCert(domain)">
        <CIcon :name="check_icon(domain)" v-if="hasSslCert(domain) && domain.sslConfig.cert_type == 'TXT'"/>
        <CIcon name="cil-plus" v-else-if="!hasSslCert(domain)"/>
        {{ $t('cert.Upload') }}
      </CButton>
    </li>
    <li v-if="hasSslCert(domain) && showRemoveCertButton(domain)"
        class="remove-cert-item">
      <CButton variant="ghost"
               color="danger"
               size="sm"
               :disabled="disabled"
               @click="clickRemoveCert(domain)">
        <CIcon name="cil-trash"/>
        {{ $t('cert.RemoveCert') }}
      </CButton>
    </li>
  </ul>
</template>

<style scoped>
ul {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 0;
}

li {
  list-style: none;
  display: flex;
  align-items: center;
}

li button {
  display: flex;
  align-items: center;
}

li.remove-cert-item {
  width: 110px;
  color: #d55d58;
}

button {
  color: #3f4c62;
  font-size: 0.8em;
}

button svg.c-icon {
  margin-right: 4px;
}
</style>

<script>
export default {
  props: ['domain', 'issueCertType', 'uploadCert', 'clickCertType', 'clickRemoveCert', 'hasSslCert', 'disabled', 'mainDomain', 'showRemoveCertButton'],
  computed: {
    is_bypass_acme: function() {
      let domain = this.domain
      if (this.mainDomain) {
        domain = this.mainDomain
      }
      if (!domain.sourceConfig) {
        return false;
      }
      if (!domain.sourceConfig.hasOwnProperty('is_bypass_acme')) {
        return false;
      }
      return domain.sourceConfig.is_bypass_acme;
    },
    alias_dns: function() {
      let main_domain = null;
      let domain = this.domain;
      let cname = null
      if (this.mainDomain) {
        main_domain = this.mainDomain;
        cname = `${main_domain.cname}`;
      } else {
        cname = `${domain.cname}`;
      }

      if (cname) {
        cname = cname.replaceAll(".", "-");
      } 
      const domain_pattern = domain.name.replaceAll('*.', '').replaceAll('.', '-');
      return this.$t('cert.DNSAliasInstruction', {acme_challenge_domain: `_acme-challenge.${domain.name.replaceAll('*.', '')}`, acme_challenge_alias: `_acme-challenge.${domain_pattern}.${cname}.5vp.net`});
    },
  },
  methods: {
    check_icon(d) {
      if( d.sslConfig.issue_state == 'error' ) {
        return "cil-warning"
      }
      else {
        return "cil-check"
      }
    }
  }
}

</script>
