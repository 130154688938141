<template>
  <div>
      <p>{{ $t('helps.guard_configuration_overview') }}</p>

      <CTabs variant="pills" vertical>
        <CTab active>
          <template slot="title">
            <CIcon name="cil-shield-alt"/>
            {{ $t('domain.DetectionShield') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.domain_detection_shield')"></CAlert>

          <CSelect
              :value.sync="guardConfig.redirect_check"
              :options="detectionShieldOptions"
          />
          <p v-if="isMixedValue(guardConfig.redirect_check)" class="domain-settings-mixed-selection">
            <CIcon name="cil-lightbulb"/>&nbsp;
            <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
          </p>

          <!-- hide this hr since CAlert and CListGroup is hidden - when they are visible again add this back to separate the controls from the CSelect -->
          <!-- <hr>-->

          <CAlert hidden color="warning" v-html="$t('helps.domain_detection_shield_whitelist')"></CAlert>

          <CListGroup hidden>
            <CListGroupItem v-for="(url, index) in this.guardConfig.redirect_white_urls" v-bind:key="url">
              {{ url }}
              <CButtonClose v-on:click="guardConfig.redirect_white_urls.splice(index, 1)"/>
            </CListGroupItem>
            <CListGroupItem class="d-flex justify-content-between align-items-center">
              <CForm class="w-100">
                <CInput
                    v-model="dsWhitelistURL"
                    style="width: 95%"
                    @keyup="handleDetectionShieldInputKeyup"
                    :disabled="guardConfig.redirect_check == null"
                    :placeholder="$t('helps.PleaseEnterPathList')"
                    :isValid="formValidStates.detectionShield"
                    :invalidFeedback="$t('helps.invalid_domain_format')"
                >
                </CInput>
              </CForm>
              <CButton class="close"
                       size="sm"
                       v-on:click="addDetectionShieldWhitelistURL"
                       color="black">
                +
              </CButton>
            </CListGroupItem>
          </CListGroup>
        </CTab>
        <CTab>
          <template slot="title">
            <CIcon name="cil-speedometer"/>
            {{ $t('domain.AccessSpeed') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.domain_access_speed')"></CAlert>

          <CRow form class="form-group">
            <CCol sm="6">
              <CInput type="number"
                      :label="$t('domain.FrequencyOfVisit')"
                      v-model.number="reqAccessSpeed"
                      min="0"></CInput>
            </CCol>
            <CCol sm="6">
              <CInput type="number"
                      :label="$t('domain.TimeInterval')"
                      v-model.number="secAccessSpeed"
                      min="0"></CInput>
            </CCol>
          </CRow>

          <div v-if="isMixedValue(guardConfig.limit_req)" style="margin-top: -25px;">
            <p class="domain-settings-mixed-selection">
              <CIcon name="cil-lightbulb"/>&nbsp;
              <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
            </p>
          </div>
          <CAlert color="info" v-else-if="reqAccessSpeed > 0">
            <i18n path="helps.user_access_params" tag="span">
              <template v-slot:req>
                {{ reqAccessSpeed }}
              </template>
              <template v-slot:sec>
                {{ secAccessSpeed }}
              </template>
            </i18n>
          </CAlert>
          <CAlert color="info" v-else>
            {{ $t('helps.UsersHaveUnrestrictedAccess') }}
          </CAlert>

        </CTab>
        <CTab>
          <template slot="title">
            <CIcon name="cil-globe-alt"/>
            {{ $t('domain.OriginServerProtection') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.domain_origin_server_protection')"></CAlert>

          <CInput type="number"
                  min="0"
                  max="5000"
                  :description="`Set to 0 for no limit; up to ${originServerProtectionLimit} only.`"
                  @change="handleOriginServerProtectionInputChange"
                  :value="originServerProtection"/>

          <div v-if="isMixedValue(guardConfig.source_conn)">
            <p class="domain-settings-mixed-selection">
              <CIcon name="cil-lightbulb"/>&nbsp;
              <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
            </p>
          </div>
        </CTab>
        <CTab>
          <template slot="title">
            <CIcon name="cil-shield-alt"/>
            {{ $t('domain.MaliciousCrawler') }}
          </template>

          <p>{{ $t('helps.domain_malicious_crawler') }}</p>

          <div class="bad-robots-control">
            <CSwitch :checked="isBadRobotsChecked"
                     @update:checked="handleBadRobotsChanged"
                     :color="isMixedValue(guardConfig.badrobots) ? 'secondary' : 'success'"></CSwitch>
            <span>
              <span v-if="isMixedValue(guardConfig.badrobots)">{{ $t('mixed_selection') }}</span>
              <span v-else-if="guardConfig.badrobots">{{ $t('enabled') }}</span>
              <span v-else>{{ $t('disabled') }}</span>
            </span>
          </div>

          <div v-if="isMixedValue(guardConfig.badrobots)" style="margin-top: 20px;">
            <p class="domain-settings-mixed-selection">
              <CIcon name="cil-lightbulb"/>&nbsp;
              <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
            </p>
          </div>
        </CTab>

        <CTab>
          <template slot="title">
            <CIcon name="cil-globe-alt"/>
            {{ $t('domain.CountryAccessRestrictions') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.domain_country_access_restriction')"></CAlert>

          <CountrySelector :country-restriction-data="guardConfig.geo"
                           :mixed-value-token="mixedValueToken"
                           :isBatchEdit="this.isBatchEdit"
                           @change="handleCountrySelection"/>
        </CTab>

        <CTab>
          <template slot="title">
            <CIcon name="cil-check-circle"/>
            {{ $t('domain.WhitelistedAddress') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.whitelist_addr')"></CAlert>

          <div class="d-flex" v-if="isBatchEdit">
              <!-- Overwrite Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <CButton :color="whitelistModes === 'overwrite' ? 'primary' : 'default'" @click="setMode('overwrite')" class="mr-2 border border-primary">
                  {{ $t('Overwrite') }}
                </CButton>
              </div>

              <!-- Merge Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <CButton :color="whitelistModes === 'merge' ? 'primary' : 'default'" @click="setMode('merge')" class="mr-2 border border-primary">
                  {{ $t('Merge') }}
                </CButton>
              </div>
          </div>

          <CListGroup v-if="shouldShowWhitelistSection">
            <CListGroupItem v-if="isMixedValue(guardConfig.whitelist)">
              <p>{{ $t('mixed_selection') }}</p>

              <p class="domain-settings-mixed-selection" style="margin-bottom: 0;">
                <CIcon name="cil-lightbulb"/>&nbsp;
                <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
              </p>
            </CListGroupItem>
            <CListGroupItem v-else
                            v-for="(addr, index) in this.guardConfig.whitelist"
                            v-bind:key="addr">
              {{ addr }}
              <CButtonClose v-on:click="guardConfig.whitelist.splice(index, 1)"/>
            </CListGroupItem>

            <CListGroupItem class="d-flex justify-content-between align-items-center">
              <CForm class="w-100">
                <CTextarea
                    name="whitelist"
                    v-model="whitelistAddr"
                    style="width: 95%"
                    :isValid="formValidStates.whitelist"
                    @keyup="handleAddrInputKeyup"
                    :placeholder="$t('helps.enter_ip_address')"
                    :description="$t('InputFormat') + ': 202.120.20.1, 202.120.20.0/24'"
                >
                </CTextarea>
              </CForm>
              <CButton class="close"
                       size="sm"
                       v-on:click="addWhitelistAddr"
                       color="black">
                +
              </CButton>
            </CListGroupItem>
          </CListGroup>
        </CTab>

        <CTab>
          <template slot="title">
            <CIcon name="cil-x-circle"/>
            {{ $t('domain.BlacklistedAddress') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.blacklist_addr')"></CAlert>

          <div class="d-flex" v-if="isBatchEdit">
              <!-- Overwrite Button -->
              <div class="d-flex align-items-cblacklistModeenter mb-1 mt-2 has-icon">
                <CButton :color="blacklistModes === 'overwrite' ? 'primary' : 'default'" @click="setModebalckip('overwrite')" class="mr-2 border border-primary">
                  {{ $t('Overwrite') }}
                </CButton>
              </div>

              <!-- Merge Button -->
              <div class="d-flex align-items-center mb-1 mt-2 has-icon">
                <CButton :color="blacklistModes === 'merge' ? 'primary' : 'default'" @click="setModebalckip('merge')" class="mr-2 border border-primary">
                  {{ $t('Merge') }}
                </CButton>
              </div>
          </div>

          <CListGroup v-if="shouldShowblacklistSection">
            <CListGroupItem v-if="isMixedValue(guardConfig.blacklist)">
              <p>{{ $t('mixed_selection') }}</p>

              <p class="domain-settings-mixed-selection" style="margin-bottom: 0;">
                <CIcon name="cil-lightbulb"/>&nbsp;
                <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
              </p>
            </CListGroupItem>
            <CListGroupItem v-else
                            v-for="(addr, index) in this.guardConfig.blacklist"
                            v-bind:key="addr">
              {{ addr }}
              <CButtonClose v-on:click="guardConfig.blacklist.splice(index, 1)"/>
            </CListGroupItem>

            <CListGroupItem class="d-flex justify-content-between align-items-center">
              <CForm class="w-100">
                <CTextarea
                    name="blacklist"
                    v-model="blacklistAddr"
                    style="width: 95%"
                    :isValid="formValidStates.blacklist"
                    @keyup="handleAddrInputKeyup"
                    :placeholder="$t('helps.enter_ip_address')"
                    :description="$t('InputFormat') + ': 202.120.20.1, 202.120.20.0/24'"
                >
                </CTextarea>
              </CForm>
              <CButton class="close"
                       size="sm"
                       v-on:click="addBlacklistAddr"
                       color="black">
                +
              </CButton>
            </CListGroupItem>
          </CListGroup>
        </CTab>
        <CTab>
          <template slot="title">
            <CIcon name="cil-browser"/>
            {{ $t('domain.VipCustomRule') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.vip_high_defense_intelligent_protection')"></CAlert>

        </CTab>
        
        <CTab>
          <template slot="title">
            <CIcon name="cil-globe-alt"/>
            {{ $t('domain.CustomErrorPage') }}
          </template>
          
          <p>{{ $t('message.CustomErrorPageDescription') }}</p>

          <CInput v-model="errorPage401"
                  :is-valid="checkCustomErrorPageUrl"
                  :label="$t('message.StatusCode401')" 
                  :description="$t('message.StatusCode401Description')" />
          <CInput v-model="errorPage425" 
                  :is-valid="checkCustomErrorPageUrl"
                  :label="$t('message.StatusCode425')" 
                  :description="$t('message.StatusCode425Description')" />
          <CInput v-model="errorPage502"
                  :is-valid="checkCustomErrorPageUrl"
                  :label="$t('message.StatusCode502')" 
                  :description="$t('message.StatusCode502Description')" />

          <div v-if="isMixedValue(guardConfig.error_code)">
            <p class="domain-settings-mixed-selection">
              <CIcon name="cil-lightbulb"/>&nbsp;
              <span>{{ $t('message.domain_settings_mixed_selection') }}</span>
            </p>
          </div>
        </CTab>

        <CTab disabled>
          <template slot="title">
            <CIcon name="cil-shield-alt"/>
            {{ $t('domain.SecurityCheck') }}
          </template>

          <p>{{ $t('helps.domain_security_check') }}</p>

          <CRow form class="form-group">
            <CCol sm="6">
              <CSwitch :checked="isSafeDetectChecked"
                       @update:checked="handleSafeDetectChanged"
                       color="success"></CSwitch>
            </CCol>
          </CRow>

          <CAlert color="info">{{ $t('helps.domain_security_check_note') }}</CAlert>
        </CTab>

        <CTab disabled>
          <template slot="title">
            <CIcon name="cil-browser"/>
            {{ $t('domain.CustomUrlProtection') }}
          </template>

          <CAlert color="warning" v-html="$t('helps.domain_custom_url_protection')"></CAlert>

          <CRow form class="form-group">
            <CCol sm="12">
              <CInput v-model="customUrlRule"
                      :label="$t('domain.UrlToBeProtected')"/>
            </CCol>
          </CRow>

          <CRow form class="form-group">
            <CCol sm="12">
              <CInput type="number"
                      :label="$t('domain.FrequencyOfVisit')"
                      v-model="reqUrlRule"
                      :description="$t('helps.frequency_visit_input_description')"
                      min="0"></CInput>
            </CCol>
          </CRow>

        </CTab>


      </CTabs>
    </div>
</template>

<style scoped>
.bad-robots-control {
  display: flex;
  align-items: center;
}

.bad-robots-control > span {
  margin-left: 5px;
}

.domain-settings-mixed-selection {
  font-size: 0.9em;
  color: #825d17;
}
</style>

<script>
import {assign, isEmpty, isArray} from 'lodash';
import DomainValidationMixin from '@/utilities/DomainValidationMixin';
import CountrySelector from '@/views/domain/CountrySelector/CountrySelector';
import {MIXED_VALUE} from '@/utilities/constants';
import {EventBus} from "@/plugins/event-bus.js";

export function guardConfigDefaultFieldValues() {
  return {
    source_conn: 0,
    redirect_check: null,
    whitelist: [],
    blacklist: [],
    limit_req: [0, 0],
    badrobots: false,
    geo: [null, []],
    redirect_white_urls: [],
    safe_detect: false,
    url_rule: [0, ''],
    error_code: {
      '401': null,
      '425': null,
      '502': null
    },
    whitelistMode: 'whitelist',
    blacklistMode: 'blacklist',
  };
}

export default {
  name: 'GuardConfiguration',
  components: {CountrySelector},
  mixins: [DomainValidationMixin],
  props: {
    isBatchEdit: Boolean,
    guardConfig: Object
  },
  data() {
    return {
      // ui states
      formValidStates: {
        detectionShield: null,
        whitelist: null,
        blacklist: null
      },
      whitelistAddr: '',
      blacklistAddr: '',
      dsWhitelistURL: '',
      pendingValues: {},
      originServerProtectionLimit: 5000,
      mixedValueToken: MIXED_VALUE,
      internalWhitelistMode: '',
      internalblacklistMode:'',

      // domain guard data
      ...guardConfigDefaultFieldValues()
    };
  },
  watch: {
    guardConfig() {
      Object.assign(this.$data, guardConfigDefaultFieldValues());

      // reset form
      this.formValidStates.detectionShield = null;
      this.formValidStates.whitelist = null;
      this.formValidStates.blacklist = null;
      this.whitelistAddr = '';
      this.blacklistAddr = '';
      this.dsWhitelistURL = '';
      this.emitPendingValues({});
    },
    'guardConfig.redirect_check': function () {
      if (this.guardConfig.redirect_check === null) {
        this.guardConfig.redirect_white_urls = [];
        this.dsWhitelistURL = '';
      }
    }
  },
  created() {
    EventBus.$on('ChangeToOverride', () => {
      this.whitelistMode = 'whitelist';
      this.blacklistMode = 'blacklist';
    });
  },
  computed: {
    shouldShowWhitelistSection() {
      return !this.isBatchEdit || this.whitelistModes;
    },
    shouldShowblacklistSection() {
      return !this.isBatchEdit || this.blacklistModes;
    },
    detectionShieldOptions: function () {
      let options = [
        {value: null, label: this.$t('None')},
        {value: 'disabled', label: this.$t('disabled')},
        {value: 'cookie', label: this.$t('domain.Cookie')},
        {value: 'jstest', label: this.$t('domain.JSTest')}
      ];

      const isMixed = this.isMixedValue(this.guardConfig.redirect_check);
      if (isMixed) {
        options = [
          ...options,
          {value: MIXED_VALUE, label: ' '}
        ];
      }

      return options;
    },
    whitelistModes: {
      get() {
        // Return the value you want to expose
        return this.internalWhitelistMode;
      },
      set(value) {
        // Handle the value update
        this.guardConfig.whitelistMode = value;
        this.internalWhitelistMode = value; // Update the internal data property
      },
    },
    blacklistModes: {
      get() {
        // Return the value you want to expose
        return this.internalblacklistMode;
      },
      set(value) {
        this.guardConfig.blacklistMode = value;
        this.internalblacklistMode = value; // Update the internal data property
      },
    },
    reqAccessSpeed: {
      get() {
        if (this.isMixedValue(this.guardConfig.limit_req))
          return null;

        if (!this.guardConfig.limit_req || !this.guardConfig.limit_req.length)
          return 0;

        return this.guardConfig.limit_req[0] === null ? 0 : this.guardConfig.limit_req[0];
      },
      set(value) {
        const newReqValue = value ? parseInt(value) : 0;
        let _limitReq = [newReqValue, 0];

        if (this.guardConfig.limit_req.length === 2)
          _limitReq = [newReqValue, this.guardConfig.limit_req[1]];

        this.guardConfig.limit_req = _limitReq;
      }
    },
    secAccessSpeed: {
      get() {
        if (this.isMixedValue(this.guardConfig.limit_req))
          return null;

        if (!this.guardConfig.limit_req || this.guardConfig.limit_req.length !== 2)
          return 0;

        return this.guardConfig.limit_req[1] === null ? 0 : this.guardConfig.limit_req[1];
      },
      set(value) {
        const newReqValue = value ? parseInt(value) : 0;
        let _limitReq = [0, newReqValue];

        if (this.guardConfig.limit_req.length === 2)
          _limitReq = [this.guardConfig.limit_req[0], newReqValue];

        this.guardConfig.limit_req = _limitReq;
      }
    },
    originServerProtection: function () {
      if (this.isMixedValue(this.guardConfig.source_conn))
        return null;

      return this.guardConfig.source_conn;
    },
    reqUrlRule: {
      get() {
        if (!this.guardConfig.url_rule || !this.guardConfig.url_rule.length)
          return 0;

        return this.guardConfig.url_rule[0];
      },
      set(value) {
        const customUrlRule = this.guardConfig.url_rule;
        const _customUrlRule = [parseInt(value)];
        const defaultReq = 0;

        if (!customUrlRule)
          _customUrlRule.push(defaultReq);
        else if (customUrlRule.length === 2)
          _customUrlRule.push(customUrlRule[1]);

        this.guardConfig.url_rule = _customUrlRule;
      }
    },
    customUrlRule: {
      get() {
        if (!this.guardConfig.url_rule || this.guardConfig.url_rule.length !== 2)
          return '';

        return this.guardConfig.url_rule[1];
      },
      set(value) {
        const customUrlRuleBase = this.guardConfig.url_rule;
        const defaultSec = 0;
        let _customUrlRule = [];

        if (!customUrlRuleBase) {
          _customUrlRule.push(defaultSec);
        } else if (customUrlRuleBase.length >= 1) {
          _customUrlRule.push(customUrlRuleBase[0]);
        }

        _customUrlRule.push(value);

        this.guardConfig.url_rule = _customUrlRule;
      }
    },
    isBadRobotsChecked: function() {
      return this.isMixedValue(this.guardConfig.badrobots) || this.guardConfig.badrobots;
    },
    isSafeDetectChecked: function() {
      return this.isMixedValue(this.guardConfig.safe_detect) || this.guardConfig.safe_detect;
    },
    errorPage401: {
      get() {
        if (this.isMixedValue(this.guardConfig.error_code))
          return null;
        
        return this.guardConfig.error_code['401'];
      },
      set(value) {
        if (this.isMixedValue(this.guardConfig.error_code)) {
          this.guardConfig.error_code = guardConfigDefaultFieldValues().error_code;  
        }
        
        this.guardConfig.error_code['401'] = value;
      }
    },
    errorPage425: {
      get() {
        if (this.isMixedValue(this.guardConfig.error_code))
          return null;
        
        return this.guardConfig.error_code['425'];
      },
      set(value) {
        if (this.isMixedValue(this.guardConfig.error_code)) {
          this.guardConfig.error_code = guardConfigDefaultFieldValues().error_code;  
        }
        
        this.guardConfig.error_code['425'] = value;
      }
    },
    errorPage502: {
      get() {
        if (this.isMixedValue(this.guardConfig.error_code))
          return null;
        
        return this.guardConfig.error_code['502'];
      },
      set(value) {
        if (this.isMixedValue(this.guardConfig.error_code)) {
          this.guardConfig.error_code = guardConfigDefaultFieldValues().error_code;  
        }
        
        this.guardConfig.error_code['502'] = value;
      }
    },
  },
  methods: {
    setMode(mode) {
      this.whitelistModes = mode;
    },
    setModebalckip(mode){
      this.blacklistModes = mode;
    },
    emitPendingValues(values) {
      if (isEmpty(values)) {
        this.pendingValues = {};
      } else {
        this.pendingValues = {...this.pendingValues, ...values};
      }
    },
    processPendingValues() {
      if (isEmpty(this.pendingValues)) {
        return;
      }

      for (let field in this.pendingValues) {
        const pendingFieldValue = this.pendingValues[field];
        if (isEmpty(pendingFieldValue)) {
          continue;
        }

        if (field === 'redirect_white_url') {
          this.guardConfig.redirect_white_urls.push(pendingFieldValue);
        } else if (['whitelist', 'blacklist'].includes(field)) {
          // this.guardConfig[field] can be a string MIXED_VALUE
          const existing = (this.guardConfig[field] && isArray(this.guardConfig[field])) ? this.guardConfig[field] : [];
          this.guardConfig[field] = [...existing, ...pendingFieldValue];
        }
      }

      this.emitPendingValues({});
    },
    handleDetectionShieldInputKeyup(e) {
      const val = e.target.value;

      const pendingValues = {};
      pendingValues['redirect_white_url'] = this.path_validator(val) ? val : '';

      this.emitPendingValues(pendingValues);

      if (val.length === 0) {
        this.formValidStates.detectionShield = null;
      } else {
        this.formValidStates.detectionShield = this.path_validator(val);
      }
    },
    addDetectionShieldWhitelistURL() {
      if (this.dsWhitelistURL.length === 0 || !this.path_validator(this.dsWhitelistURL))
        return false;

      if (!this.guardConfig.redirect_white_urls.includes(this.dsWhitelistURL))
        this.guardConfig.redirect_white_urls.push(this.dsWhitelistURL)
      this.dsWhitelistURL = ''

      this.formValidStates.detectionShield = null;

      return true;
    },
    handleOriginServerProtectionInputChange(value) {
      const _value = parseInt(value);
      if (_value <= this.originServerProtectionLimit) {
        this.guardConfig.source_conn = _value;
      } else {
        this.guardConfig.source_conn = this.originServerProtectionLimit;
      }
    },
    handleCountrySelection(selection) {
      assign(this.guardConfig, {geo: selection});
    },
    addAddr(key, value) {
      const values = value.split('\n');

      const failed = [];

      for (let addr of values) {
        if (!this.validateIp(addr)) {
          failed.push(addr);
          continue;
        }

        if (!this.guardConfig[key].includes(addr))
          this.guardConfig[key].push(addr);
      }

      return {success: (failed.length === 0), failed};
    },
    async addWhitelistAddr() {
      // if the guardConfig.whitelist is mixed content we override it with
      // an empty array so we can append addr to it

      if (this.whitelistModes == "merge"){
        this.flash(this.$t("domain.SaveSuccessMerge"), "success", {
              timeout: 5000
        });
      }

      if (this.isMixedValue(this.guardConfig.whitelist)) {
        this.guardConfig.whitelist = [];
      }

      if (this.whitelistAddr == "0.0.0.0") {
        this.formValidStates.whitelist = false;
        this.flash(this.$t('message.input_parse_success_with_errors'), 'warning', {"timeout": 5000});
        this.emitPendingValues({whitelist: []});
        return true;
      }        

      const {success, failed} = this.addAddr('whitelist', this.whitelistAddr);
      if (!success) {
        this.whitelistAddr = failed.join('\n');
        this.formValidStates.whitelist = false;

        this.flash(this.$t('message.input_parse_success_with_errors'), 'warning', {"timeout": 5000});
      } else {
        this.whitelistAddr = '';
        this.formValidStates.whitelist = null;
      }

      this.emitPendingValues({whitelist: []});

      return true;
    },
    async addBlacklistAddr() {
      // if the guardConfig.blacklist is mixed content we override it with
      // an empty array so we can append addr to it

      if (this.blacklistModes == "merge"){
        this.flash(this.$t("domain.SaveSuccessMerge"), "success", {
              timeout: 5000
        });
      }

      if (this.isMixedValue(this.guardConfig.blacklist)) {
        this.guardConfig.blacklist = [];
      }

      const {success, failed} = this.addAddr('blacklist', this.blacklistAddr);
      if (!success) {
        this.blacklistAddr = failed.join('\n');
        this.formValidStates.blacklist = false;

        this.flash(this.$t('message.input_parse_success_with_errors'), 'warning', {"timeout": 5000});
      } else {
        this.blacklistAddr = '';
        this.formValidStates.blacklist = null;
      }

      this.emitPendingValues({blacklist: []});

      return true;
    },
    validateIp(value) {
      const regex = /^(?:(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\s*\/(3[0-2]|[12]?\d))?$|^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}(\s*\/(12[0-8]|1[01]?[0-9]|[1-9]?[0-9]))?$/;
      return regex.test(value);
    },
    handleAddrInputKeyup(e) {
      const field = e.target.name;
      const value = e.target.value;

      const addrValues = value.split('\n');
      if (addrValues.length > 1) {
        this.formValidStates[field] = null;
      } else {
        this.formValidStates[field] = this.validateIp(value);
      }

      const pending = [];
      const existingValues = this.guardConfig[field] ? this.guardConfig[field] : [];

      for (let v of addrValues) {
        if (this.validateIp(v) && !existingValues.includes(v)) {
          pending.push(v);
        }
      }

      const pendingValues = {};
      pendingValues[field] = pending;

      this.emitPendingValues(pendingValues);
    },
    isMixedValue(value) {
      return value === MIXED_VALUE;
    },
    handleBadRobotsChanged(value) {
      this.guardConfig.badrobots = value;
    },
    handleSafeDetectChanged(value) {
      this.guardConfig.safe_detect = value;
    },
    checkCustomErrorPageUrl(value) {
      if (!value)
        return;
      
      return this.source_validator(value);
    }
  }
}
</script>
