import { render, staticRenderFns } from "./GuardConfiguration.vue?vue&type=template&id=32d663d9&scoped=true&"
import script from "./GuardConfiguration.vue?vue&type=script&lang=js&"
export * from "./GuardConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./GuardConfiguration.vue?vue&type=style&index=0&id=32d663d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d663d9",
  null
  
)

export default component.exports